import React from 'react'
import Img from 'gatsby-image'

import Container from '../../components/Container/Container'
import Hero from '../../components/Hero/Hero'
import Layout from '../../components/Layout/Layout'
import SEO from '../../components/SEO/SEO'
import Section from '../../components/Section/Section'

import './index.css'
import { graphql } from 'gatsby'

const AboutPage = ({
  data: {
    chanitaImageData: { childImageSharp: chanitaImageSharp },
    valuesIconsData: { edges: valuesIconsEdges },
  },
}) => (
    <Layout>
      <SEO title="About The People and Mission" />
      <Hero title="About The People and Mission of Melanin.Tech">
        <p>Our mission is to accelerate the growth of the Black tech ecosystem by helping members obtain opportunities in the industry and sustain long-term, rewarding careers. We are here to cultivate a close-knit community and provide a safe space for people who either work in the tech industry or aspire to do so.</p>
      </Hero>
      <Section className="Values light">
        <Container>
          <h2>What we believe&nbsp;in</h2>
          <div className="Values--grid">
            <div>
              <Img
                {...valuesIconsEdges
                  .find(({ node: { relativePath } }) => relativePath.includes('Development'))
                  .node.childImageSharp
                }
              />
              <h4>Development</h4>
              <p>We’re here to aid in the development of your expertise, goals, and initiatives through action that creates growth, progress, positive change in the quality of life.</p>
            </div>
            <div>
              <Img
                {...valuesIconsEdges
                  .find(({ node: { relativePath } }) => relativePath.includes('Diversity'))
                  .node.childImageSharp
                }
              />
              <h4>Diversity</h4>
              <p>Diversity and valuing differences are key parts to the foundation of success in tech. Together we’ll create a pipeline to erase the status quo of the industry, one job at a time.</p>
            </div>
            <div>
              <Img
                {...valuesIconsEdges
                  .find(({ node: { relativePath } }) => relativePath.includes('Education'))
                  .node.childImageSharp
                }
              />
              <h4>Education</h4>
              <p>We aim to deliver an enlightening experience by providing you with tools and resources to obtain knowledge through involvement, study, or being taught.</p>
            </div>
            <div>
              <Img
                {...valuesIconsEdges
                  .find(({ node: { relativePath } }) => relativePath.includes('Liberation'))
                  .node.childImageSharp
                }
              />
              <h4>Liberation</h4>
              <p>Liberatory practices are those that dismantle the sources of inequity and inequality. Our goal is to further liberate Black people in tech from industry-wide oppression.</p>
            </div>
          </div>
        </Container>
      </Section>
      <Section className="Team">
        <Container>
          <h2>Team &amp; Board Members</h2>
          <div className="Team--member">
            <div>
              <Img {...chanitaImageSharp} alt="Chanita Christmas, Founder" />
            </div>
            <div>
              <h3>Chanita Christmas</h3>
              <h4 className="Team--member-title">Founder</h4>
              <p>World traveler, photographer, and DEI in tech advocate, Chanita is dedicated to leading this movement. Her mission is to create lasting change in the tech industry that she knows and loves. Her brand of change focuses on inspiring others to stand up for what they believe in and help create opportunities for as many people as possible. Chanita can be found online at <a href="//twitter.com/chanitaxmas">@chanitaxmas</a>.</p>
            </div>
          </div>
        </Container>
      </Section>
    </Layout>
  )

export const pageQuery = graphql`
  query AboutPageQuery {
    valuesIconsData: allFile(filter: { relativePath: { regex: "/ValuesIcons\//" } }) {
      edges {
        node {
          relativePath
          childImageSharp {
            fixed(width: 120, quality: 80) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
      }
    }
    chanitaImageData: file(relativePath: { eq: "AboutPage/Team--Chanita.png" }) {
      childImageSharp {
        fluid(maxWidth: 568, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default AboutPage
